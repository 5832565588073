import actions from './actions';

const { 
    EXTENDEDWARRANTY_PURCHASE_LIST_DATA_BEGIN, EXTENDEDWARRANTY_PURCHASE_LIST_DATA_SUCCESS, EXTENDEDWARRANTY_PURCHASE_LIST_DATA_ERR,
    EXTENDEDWARRANTY_LIST_DATA_BEGIN, EXTENDEDWARRANTY_LIST_DATA_SUCCESS, EXTENDEDWARRANTY_LIST_DATA_ERR,
    EXTENDEDWARRANTY_SCHEDULED_LIST_DATA_BEGIN, EXTENDEDWARRANTY_SCHEDULED_LIST_DATA_SUCCESS, EXTENDEDWARRANTY_SCHEDULED_LIST_DATA_ERR,
    EXTENDEDWARRANTY_PRIORITY_LIST_DATA_BEGIN,EXTENDEDWARRANTY_PRIORITY_LIST_DATA_SUCCESS,EXTENDEDWARRANTY_PRIORITY_LIST_DATA_ERR,
    
    ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_BEGIN, ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_SUCCESS, ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_ERR,ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_RESET,
    EXTENDEDWARRANTY_VENDOR_DISPOSITION_LIST_DATA_BEGIN,EXTENDEDWARRANTY_VENDOR_DISPOSITION_LIST_DATA_SUCCESS,EXTENDEDWARRANTY_VENDOR_DISPOSITION_LIST_DATA_ERR,
    EXTENDEDWARRANTY_VENDOR_LIST_DATA_BEGIN, EXTENDEDWARRANTY_VENDOR_LIST_DATA_SUCCESS, EXTENDEDWARRANTY_VENDOR_LIST_DATA_ERR,
    EXTENDEDWARRANTY_VENDOR_SCHEDULED_LIST_DATA_BEGIN, EXTENDEDWARRANTY_VENDOR_SCHEDULED_LIST_DATA_SUCCESS, EXTENDEDWARRANTY_VENDOR_SCHEDULED_LIST_DATA_ERR,
    EXTENDEDWARRANTY_VENDOR_PRIORITY_LIST_DATA_BEGIN, EXTENDEDWARRANTY_VENDOR_PRIORITY_LIST_DATA_SUCCESS, EXTENDEDWARRANTY_VENDOR_PRIORITY_LIST_DATA_ERR,

    ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_BEGIN, ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_SUCCESS, ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_ERR,ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_RESET,
    EXTENDEDWARRANTY_AGENT_DISPOSITION_LIST_DATA_BEGIN,EXTENDEDWARRANTY_AGENT_DISPOSITION_LIST_DATA_SUCCESS,EXTENDEDWARRANTY_AGENT_DISPOSITION_LIST_DATA_ERR,
    EXTENDEDWARRANTY_AGENT_LIST_DATA_BEGIN, EXTENDEDWARRANTY_AGENT_LIST_DATA_SUCCESS, EXTENDEDWARRANTY_AGENT_LIST_DATA_ERR,
    EXTENDEDWARRANTY_AGENT_SCHEDULED_LIST_DATA_BEGIN, EXTENDEDWARRANTY_AGENT_SCHEDULED_LIST_DATA_SUCCESS, EXTENDEDWARRANTY_AGENT_SCHEDULED_LIST_DATA_ERR,
    EXTENDEDWARRANTY_AGENT_PRIORITY_LIST_DATA_BEGIN, EXTENDEDWARRANTY_AGENT_PRIORITY_LIST_DATA_SUCCESS, EXTENDEDWARRANTY_AGENT_PRIORITY_LIST_DATA_ERR,
    
    UPDATE_STATUS_DATA_BEGIN, UPDATE_STATUS_DATA_SUCCESS, UPDATE_STATUS_DATA_ERR, UPDATE_STATUS_DATA_RESET,
    VIEW_EXTENDEDWARRANTY_DATA_BEGIN, VIEW_EXTENDEDWARRANTY_DATA_SUCCESS, VIEW_EXTENDEDWARRANTY_DATA_ERR,
    EXTENDEDWARRANTY_HISTORY_DATA_BEGIN, EXTENDEDWARRANTY_HISTORY_DATA_SUCCESS, EXTENDEDWARRANTY_HISTORY_DATA_ERR,
    
    EXTENDEDWARRANTY_REPORT_DATE_BEGIN, EXTENDEDWARRANTY_REPORT_DATE_SUCCESS, EXTENDEDWARRANTY_REPORT_DATE_ERR,
    EXTENDEDWARRANTY_REPORT_SAVE_DATA_BEGIN, EXTENDEDWARRANTY_REPORT_SAVE_DATA_SUCCESS,EXTENDEDWARRANTY_REPORT_SAVE_DATA_ERR,
    EXTENDEDWARRANTY_REPORT_DOWNLOAD_DATA_BEGIN, EXTENDEDWARRANTY_REPORT_DOWNLOAD_DATA_SUCCESS, EXTENDEDWARRANTY_REPORT_DOWNLOAD_DATA_ERR,

}= actions;

const initialState = { data: [], loading: false, error: null, };

const extendedwarrantyPurchaseListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_PURCHASE_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_PURCHASE_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_PURCHASE_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const extendedwarrantyListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const extendedwarrantyScheduledListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_SCHEDULED_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_SCHEDULED_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_SCHEDULED_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const extendedwarrantyPriorityListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_PRIORITY_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_PRIORITY_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_PRIORITY_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const assignVendorExtendedWarrantyReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};

const extendedwarrantyVendorListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_VENDOR_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_VENDOR_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_VENDOR_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const extendedwarrantyVendorScheduledListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_VENDOR_SCHEDULED_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_VENDOR_SCHEDULED_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_VENDOR_SCHEDULED_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const extendedwarrantyVendorPriorityListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_VENDOR_PRIORITY_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_VENDOR_PRIORITY_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_VENDOR_PRIORITY_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const extendedwarrantyVendorDispositionListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_VENDOR_DISPOSITION_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_VENDOR_DISPOSITION_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_VENDOR_DISPOSITION_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const assignAgentExtendedWarrantyReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};

const extendedwarrantyAgentListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_AGENT_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_AGENT_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_AGENT_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const extendedwarrantyAgentScheduledListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_AGENT_SCHEDULED_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_AGENT_SCHEDULED_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_AGENT_SCHEDULED_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const extendedwarrantyAgentPriorityListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_AGENT_PRIORITY_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_AGENT_PRIORITY_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_AGENT_PRIORITY_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const extendedwarrantyAgentDispositionListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_AGENT_DISPOSITION_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_AGENT_DISPOSITION_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_AGENT_DISPOSITION_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const updateStatusReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPDATE_STATUS_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case UPDATE_STATUS_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case UPDATE_STATUS_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case UPDATE_STATUS_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};

const viewExtendWarrantyReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case VIEW_EXTENDEDWARRANTY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case VIEW_EXTENDEDWARRANTY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case VIEW_EXTENDEDWARRANTY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const extendedwarrantyHistoryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_HISTORY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_HISTORY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_HISTORY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const extendedwarrantyReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_REPORT_DATE_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_REPORT_DATE_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_REPORT_DATE_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const extendedwarrantyReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_REPORT_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const extendedwarrantyReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXTENDEDWARRANTY_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXTENDEDWARRANTY_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXTENDEDWARRANTY_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

export { 
    extendedwarrantyPurchaseListReducers,extendedwarrantyListReducers,extendedwarrantyScheduledListReducers,extendedwarrantyPriorityListReducers,
    assignVendorExtendedWarrantyReducers, extendedwarrantyVendorListReducers,  extendedwarrantyVendorScheduledListReducers,extendedwarrantyVendorPriorityListReducers, extendedwarrantyVendorDispositionListReducers,
    assignAgentExtendedWarrantyReducers, extendedwarrantyAgentListReducers,  extendedwarrantyAgentScheduledListReducers,extendedwarrantyAgentPriorityListReducers, extendedwarrantyAgentDispositionListReducers,
    extendedwarrantyHistoryReducers,viewExtendWarrantyReducers,updateStatusReducers,
    extendedwarrantyReportReducers, extendedwarrantyReportSaveReducers, extendedwarrantyReportDownloadReducers  
};