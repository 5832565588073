import React, { lazy } from 'react';
import { Switch, Route,useRouteMatch,Redirect } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard         = lazy(() => import('../../container/purchaseQueries/Dashboard'));
const QueriesList       = lazy(() => import('../../container/purchaseQueries/QueriesList'));
const PurchaseFollow    = lazy(() => import('../../container/purchaseQueries/ReCheckQueriesList'));
const ViewQueries       = lazy(() => import('../../container/purchaseQueries/ViewQueries'));
const Report            = lazy(() => import('../../container/purchaseQueries/Report'));
const DownloadReport    = lazy(() => import('../../container/purchaseQueries/DownloadReport'));
const SummaryReport     = lazy(() => import('../../container/purchaseQueries/SummaryReport'));


function NotFound() {
    return <Redirect to="/admin" />;
}

function CustomerQueriesRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/purchase`} component={QueriesList} />
            <Route exact path={`${path}/purchase_follow`} component={PurchaseFollow} />
            <Route exact path={`${path}/view`} component={ViewQueries} />
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route exact path={`${path}/summary_report`} component={SummaryReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(CustomerQueriesRoutes);
