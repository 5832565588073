import React, { lazy } from 'react';
import { Switch, Route,Redirect ,useRouteMatch} from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard                 = lazy(() => import('../../container/warranty/Dashboard'));
const PurchaseList              = lazy(() => import('../../container/warranty/PurchaseList'));
const RegisteredList            = lazy(() => import('../../container/warranty/RegisteredList'));

const PriorityList              = lazy(() => import('../../container/warranty/PriorityList'));
const ScheduledList             = lazy(() => import('../../container/warranty/ScheduledList'));
const Report                    = lazy(() => import('../../container/warranty/Report'));
const DownloadReport            = lazy(() => import('../../container/warranty/DownloadReport'));

function NotFound() {
    return <Redirect to="/admin" />;
}

function DataRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/purchase`} component={PurchaseList} />
            <Route exact path={`${path}/complaint`} component={RegisteredList} />
            <Route exact path={`${path}/complaint_happy`} component={RegisteredList} />
            <Route exact path={`${path}/priority`} component={PriorityList} />
            <Route exact path={`${path}/scheduled`} component={ScheduledList} />
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(DataRoutes);