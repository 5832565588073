import React, { lazy } from 'react';
import { Switch, Route,Redirect ,useRouteMatch} from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard                 = lazy(() => import('../../container/escalation/Dashboard'));
const EscalationList            = lazy(() => import('../../container/escalation/EscalationList'));
const EscalationScheduledList   = lazy(() => import('../../container/escalation/EscalationScheduledList'));
const EscalationPriorityList    = lazy(() => import('../../container/escalation/EscalationPriorityList'));
const ViewEscalationDetails     = lazy(() => import('../../container/escalation/ViewEscalation'));
// const ViewDetails               = lazy(() => import('../../container/escalation/ViewDetails'));
const Report                    = lazy(() => import('../../container/escalation/Report'));
const DownloadReport            = lazy(() => import('../../container/escalation/DownloadReport'));

function NotFound() {
    return <Redirect to="/admin" />;
}

function DataRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/list`} component={EscalationList} />
            <Route exact path={`${path}/list/view`} component={ViewEscalationDetails} />
            <Route exact path={`${path}/scheduled`} component={EscalationScheduledList} />
            <Route exact path={`${path}/scheduled/view`} component={ViewEscalationDetails} />
            <Route exact path={`${path}/priority`} component={EscalationPriorityList} />
            <Route exact path={`${path}/priority/view`} component={ViewEscalationDetails} />
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(DataRoutes);