const actions = {

    EXTENDEDWARRANTY_PURCHASE_LIST_DATA_BEGIN   : 'EXTENDEDWARRANTY_PURCHASE_LIST_DATA_BEGIN',
    EXTENDEDWARRANTY_PURCHASE_LIST_DATA_SUCCESS : 'EXTENDEDWARRANTY_PURCHASE_LIST_DATA_SUCCESS',
    EXTENDEDWARRANTY_PURCHASE_LIST_DATA_ERR     : 'EXTENDEDWARRANTY_PURCHASE_LIST_DATA_ERR',
    
    EXTENDEDWARRANTY_LIST_DATA_BEGIN   : 'EXTENDEDWARRANTY_LIST_DATA_BEGIN',
    EXTENDEDWARRANTY_LIST_DATA_SUCCESS : 'EXTENDEDWARRANTY_LIST_DATA_SUCCESS',
    EXTENDEDWARRANTY_LIST_DATA_ERR     : 'EXTENDEDWARRANTY_LIST_DATA_ERR',

    EXTENDEDWARRANTY_SCHEDULED_LIST_DATA_BEGIN     : 'EXTENDEDWARRANTY_SCHEDULED_LIST_DATA_BEGIN',
    EXTENDEDWARRANTY_SCHEDULED_LIST_DATA_SUCCESS   : 'EXTENDEDWARRANTY_SCHEDULED_LIST_DATA_SUCCESS',
    EXTENDEDWARRANTY_SCHEDULED_LIST_DATA_ERR       : 'EXTENDEDWARRANTY_SCHEDULED_LIST_DATA_ERR',

    EXTENDEDWARRANTY_PRIORITY_LIST_DATA_BEGIN     : 'EXTENDEDWARRANTY_PRIORITY_LIST_DATA_BEGIN',
    EXTENDEDWARRANTY_PRIORITY_LIST_DATA_SUCCESS   : 'EXTENDEDWARRANTY_PRIORITY_LIST_DATA_SUCCESS',
    EXTENDEDWARRANTY_PRIORITY_LIST_DATA_ERR       : 'EXTENDEDWARRANTY_PRIORITY_LIST_DATA_ERR',


    ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_BEGIN : 'ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_BEGIN',
    ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_SUCCESS: 'ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_SUCCESS',
    ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_ERR : 'ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_ERR',
    ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_RESET : 'ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_RESET',

    EXTENDEDWARRANTY_VENDOR_DISPOSITION_LIST_DATA_BEGIN : 'EXTENDEDWARRANTY_VENDOR_DISPOSITION_LIST_DATA_BEGIN',
    EXTENDEDWARRANTY_VENDOR_DISPOSITION_LIST_DATA_SUCCESS : 'EXTENDEDWARRANTY_VENDOR_DISPOSITION_LIST_DATA_SUCCESS',
    EXTENDEDWARRANTY_VENDOR_DISPOSITION_LIST_DATA_ERR : 'EXTENDEDWARRANTY_VENDOR_DISPOSITION_LIST_DATA_ERR',

    EXTENDEDWARRANTY_VENDOR_LIST_DATA_BEGIN   : 'EXTENDEDWARRANTY_VENDOR_LIST_DATA_BEGIN',
    EXTENDEDWARRANTY_VENDOR_LIST_DATA_SUCCESS      : 'EXTENDEDWARRANTY_VENDOR_LIST_DATA_SUCCESS',
    EXTENDEDWARRANTY_VENDOR_LIST_DATA_ERR     : 'EXTENDEDWARRANTY_VENDOR_LIST_DATA_ERR',

    EXTENDEDWARRANTY_VENDOR_SCHEDULED_LIST_DATA_BEGIN     : 'EXTENDEDWARRANTY_VENDOR_SCHEDULED_LIST_DATA_BEGIN',
    EXTENDEDWARRANTY_VENDOR_SCHEDULED_LIST_DATA_SUCCESS   : 'EXTENDEDWARRANTY_VENDOR_SCHEDULED_LIST_DATA_SUCCESS',
    EXTENDEDWARRANTY_VENDOR_SCHEDULED_LIST_DATA_ERR       : 'EXTENDEDWARRANTY_VENDOR_SCHEDULED_LIST_DATA_ERR',

    EXTENDEDWARRANTY_VENDOR_PRIORITY_LIST_DATA_BEGIN     : 'EXTENDEDWARRANTY_VENDOR_PRIORITY_LIST_DATA_BEGIN',
    EXTENDEDWARRANTY_VENDOR_PRIORITY_LIST_DATA_SUCCESS   : 'EXTENDEDWARRANTY_VENDOR_PRIORITY_LIST_DATA_SUCCESS',
    EXTENDEDWARRANTY_VENDOR_PRIORITY_LIST_DATA_ERR       : 'EXTENDEDWARRANTY_VENDOR_PRIORITY_LIST_DATA_ERR',

    ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_BEGIN : 'ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_BEGIN',
    ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_SUCCESS: 'ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_SUCCESS',
    ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_ERR : 'ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_ERR',
    ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_RESET : 'ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_RESET',

    EXTENDEDWARRANTY_AGENT_DISPOSITION_LIST_DATA_BEGIN : 'EXTENDEDWARRANTY_AGENT_DISPOSITION_LIST_DATA_BEGIN',
    EXTENDEDWARRANTY_AGENT_DISPOSITION_LIST_DATA_SUCCESS : 'EXTENDEDWARRANTY_AGENT_DISPOSITION_LIST_DATA_SUCCESS',
    EXTENDEDWARRANTY_AGENT_DISPOSITION_LIST_DATA_ERR : 'EXTENDEDWARRANTY_AGENT_DISPOSITION_LIST_DATA_ERR',

    EXTENDEDWARRANTY_AGENT_LIST_DATA_BEGIN   : 'EXTENDEDWARRANTY_AGENT_LIST_DATA_BEGIN',
    EXTENDEDWARRANTY_AGENT_LIST_DATA_SUCCESS      : 'EXTENDEDWARRANTY_AGENT_LIST_DATA_SUCCESS',
    EXTENDEDWARRANTY_AGENT_LIST_DATA_ERR     : 'EXTENDEDWARRANTY_AGENT_LIST_DATA_ERR',

    EXTENDEDWARRANTY_AGENT_SCHEDULED_LIST_DATA_BEGIN     : 'EXTENDEDWARRANTY_AGENT_SCHEDULED_LIST_DATA_BEGIN',
    EXTENDEDWARRANTY_AGENT_SCHEDULED_LIST_DATA_SUCCESS   : 'EXTENDEDWARRANTY_AGENT_SCHEDULED_LIST_DATA_SUCCESS',
    EXTENDEDWARRANTY_AGENT_SCHEDULED_LIST_DATA_ERR       : 'EXTENDEDWARRANTY_AGENT_SCHEDULED_LIST_DATA_ERR',

    EXTENDEDWARRANTY_AGENT_PRIORITY_LIST_DATA_BEGIN     : 'EXTENDEDWARRANTY_AGENT_PRIORITY_LIST_DATA_BEGIN',
    EXTENDEDWARRANTY_AGENT_PRIORITY_LIST_DATA_SUCCESS   : 'EXTENDEDWARRANTY_AGENT_PRIORITY_LIST_DATA_SUCCESS',
    EXTENDEDWARRANTY_AGENT_PRIORITY_LIST_DATA_ERR       : 'EXTENDEDWARRANTY_AGENT_PRIORITY_LIST_DATA_ERR',

    UPDATE_STATUS_DATA_BEGIN: 'UPDATE_STATUS_DATA_BEGIN',
    UPDATE_STATUS_DATA_SUCCESS: 'UPDATE_STATUS_DATA_SUCCESS',
    UPDATE_STATUS_DATA_ERR: 'UPDATE_STATUS_DATA_ERR',
    UPDATE_STATUS_DATA_RESET: 'UPDATE_STATUS_DATA_RESET',
    
    VIEW_EXTENDEDWARRANTY_DATA_BEGIN : 'VIEW_EXTENDEDWARRANTY_DATA_BEGIN',
    VIEW_EXTENDEDWARRANTY_DATA_SUCCESS: 'VIEW_EXTENDEDWARRANTY_DATA_SUCCESS',
    VIEW_EXTENDEDWARRANTY_DATA_ERR : 'VIEW_EXTENDEDWARRANTY_DATA_ERR',

    EXTENDEDWARRANTY_HISTORY_DATA_BEGIN : 'EXTENDEDWARRANTY_HISTORY_DATA_BEGIN',
    EXTENDEDWARRANTY_HISTORY_DATA_SUCCESS: 'EXTENDEDWARRANTY_HISTORY_DATA_SUCCESS',
    EXTENDEDWARRANTY_HISTORY_DATA_ERR : 'EXTENDEDWARRANTY_HISTORY_DATA_ERR',
   
    EXTENDEDWARRANTY_REPORT_DATE_BEGIN : 'EXTENDEDWARRANTY_REPORT_DATE_BEGIN',
    EXTENDEDWARRANTY_REPORT_DATE_SUCCESS: 'EXTENDEDWARRANTY_REPORT_DATE_SUCCESS',
    EXTENDEDWARRANTY_REPORT_DATE_ERR : 'EXTENDEDWARRANTY_REPORT_DATE_ERR',

    EXTENDEDWARRANTY_REPORT_SAVE_DATA_BEGIN : 'EXTENDEDWARRANTY_REPORT_SAVE_DATA_BEGIN',
    EXTENDEDWARRANTY_REPORT_SAVE_DATA_SUCCESS: 'EXTENDEDWARRANTY_REPORT_SAVE_DATA_SUCCESS',
    EXTENDEDWARRANTY_REPORT_SAVE_DATA_ERR : 'EXTENDEDWARRANTY_REPORT_SAVE_DATA_ERR',

    EXTENDEDWARRANTY_REPORT_DOWNLOAD_DATA_BEGIN : 'EXTENDEDWARRANTY_REPORT_DOWNLOAD_DATA_BEGIN',
    EXTENDEDWARRANTY_REPORT_DOWNLOAD_DATA_SUCCESS: 'EXTENDEDWARRANTY_REPORT_DOWNLOAD_DATA_SUCCESS',
    EXTENDEDWARRANTY_REPORT_DOWNLOAD_DATA_ERR : 'EXTENDEDWARRANTY_REPORT_DOWNLOAD_DATA_ERR',


    extendedwarrantyPurchaseListDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_PURCHASE_LIST_DATA_BEGIN,
        };
    },

    extendedwarrantyPurchaseListDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_PURCHASE_LIST_DATA_SUCCESS,
            data,
        };
    },

    extendedwarrantyPurchaseListDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_PURCHASE_LIST_DATA_ERR,
            err,
        };
    },

    extendedwarrantyListDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_LIST_DATA_BEGIN,
        };
    },

    extendedwarrantyListDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_LIST_DATA_SUCCESS,
            data,
        };
    },

    extendedwarrantyListDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_LIST_DATA_ERR,
            err,
        };
    },

    extendedwarrantyScheduledListDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_SCHEDULED_LIST_DATA_BEGIN,
        };
    },

    extendedwarrantyScheduledListDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_SCHEDULED_LIST_DATA_SUCCESS,
            data,
        };
    },

    extendedwarrantyScheduledListDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_SCHEDULED_LIST_DATA_ERR,
            err,
        };
    },

    extendedwarrantyPriorityListDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_PRIORITY_LIST_DATA_BEGIN,
        };
    },

    extendedwarrantyPriorityListDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_PRIORITY_LIST_DATA_SUCCESS,
            data,
        };
    },

    extendedwarrantyPriorityListDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_PRIORITY_LIST_DATA_ERR,
            err,
        };
    },



    assignVendorExtendedWarrantyDataBegin: () => {
        return {
            type: actions.ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_BEGIN,
        };
    },

    assignVendorExtendedWarrantyDataSuccess: (data) => {
        return {
            type: actions.ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_SUCCESS,
            data,
        };
    },

    assignVendorExtendedWarrantyDataErr: (err) => {
        return {
            type: actions.ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_ERR,
            err,
        };
    },
    
    assignVendorExtendedWarrantyDataReset: (err) => {
        return {
            type: actions.ASSIGN_VENDOR_EXTENDEDWARRANTY_DATA_RESET,
            err,
        };
    },

    extendedwarrantyVendorDispositionListDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_VENDOR_DISPOSITION_LIST_DATA_BEGIN,
        };
    },

    extendedwarrantyVendorDispositionListDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_VENDOR_DISPOSITION_LIST_DATA_SUCCESS,
            data,
        };
    },

    extendedwarrantyVendorDispositionListDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_VENDOR_DISPOSITION_LIST_DATA_ERR,
            err,
        };
    },

    extendedwarrantyVendorListDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_VENDOR_LIST_DATA_BEGIN,
        };
    },

    extendedwarrantyVendorListDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_VENDOR_LIST_DATA_SUCCESS,
            data,
        };
    },

    extendedwarrantyVendorListDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_VENDOR_LIST_DATA_ERR,
            err,
        };
    },

    extendedwarrantyVendorScheduledListDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_VENDOR_SCHEDULED_LIST_DATA_BEGIN,
        };
    },

    extendedwarrantyVendorScheduledListDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_VENDOR_SCHEDULED_LIST_DATA_SUCCESS,
            data,
        };
    },

    extendedwarrantyVendorScheduledListDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_VENDOR_SCHEDULED_LIST_DATA_ERR,
            err,
        };
    },

    extendedwarrantyVendorPriorityListDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_VENDOR_PRIORITY_LIST_DATA_BEGIN,
        };
    },

    extendedwarrantyVendorPriorityListDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_VENDOR_PRIORITY_LIST_DATA_SUCCESS,
            data,
        };
    },

    extendedwarrantyVendorPriorityListDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_VENDOR_PRIORITY_LIST_DATA_ERR,
            err,
        };
    },


    assignAgentExtendedWarrantyDataBegin: () => {
        return {
            type: actions.ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_BEGIN,
        };
    },

    assignAgentExtendedWarrantyDataSuccess: (data) => {
        return {
            type: actions.ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_SUCCESS,
            data,
        };
    },

    assignAgentExtendedWarrantyDataErr: (err) => {
        return {
            type: actions.ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_ERR,
            err,
        };
    },
    
    assignAgentExtendedWarrantyDataReset: (err) => {
        return {
            type: actions.ASSIGN_AGENT_EXTENDEDWARRANTY_DATA_RESET,
            err,
        };
    },

    extendedwarrantyAgentDispositionListDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_AGENT_DISPOSITION_LIST_DATA_BEGIN,
        };
    },

    extendedwarrantyAgentDispositionListDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_AGENT_DISPOSITION_LIST_DATA_SUCCESS,
            data,
        };
    },

    extendedwarrantyAgentDispositionListDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_AGENT_DISPOSITION_LIST_DATA_ERR,
            err,
        };
    },

    extendedwarrantyAgentListDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_AGENT_LIST_DATA_BEGIN,
        };
    },

    extendedwarrantyAgentListDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_AGENT_LIST_DATA_SUCCESS,
            data,
        };
    },

    extendedwarrantyAgentListDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_AGENT_LIST_DATA_ERR,
            err,
        };
    },

    extendedwarrantyAgentScheduledListDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_AGENT_SCHEDULED_LIST_DATA_BEGIN,
        };
    },

    extendedwarrantyAgentScheduledListDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_AGENT_SCHEDULED_LIST_DATA_SUCCESS,
            data,
        };
    },

    extendedwarrantyAgentScheduledListDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_AGENT_SCHEDULED_LIST_DATA_ERR,
            err,
        };
    },

    extendedwarrantyAgentPriorityListDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_AGENT_PRIORITY_LIST_DATA_BEGIN,
        };
    },

    extendedwarrantyAgentPriorityListDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_AGENT_PRIORITY_LIST_DATA_SUCCESS,
            data,
        };
    },

    extendedwarrantyAgentPriorityListDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_AGENT_PRIORITY_LIST_DATA_ERR,
            err,
        };
    },



    updateStatusDataBegin: () => {
        return {
            type: actions.UPDATE_STATUS_DATA_BEGIN,
        };
    },

    updateStatusDataSuccess: (data) => {
        return {
            type: actions.UPDATE_STATUS_DATA_SUCCESS,
            data,
        };
    },

    updateStatusDataErr: (err) => {
        return {
            type: actions.UPDATE_STATUS_DATA_ERR,
            err,
        };
    },
    
    updateStatusDataReset: (err) => {
        return {
            type: actions.UPDATE_STATUS_DATA_RESET,
            err,
        };
    },

    viewExtendWarrantyDataBegin: () => {
        return {
            type: actions.VIEW_EXTENDEDWARRANTY_DATA_BEGIN,
        };
    },

    viewExtendWarrantyDataSuccess: (data) => {
        return {
            type: actions.VIEW_EXTENDEDWARRANTY_DATA_SUCCESS,
            data,
        };
    },

    viewExtendWarrantyDataErr: (err) => {
        return {
            type: actions.VIEW_EXTENDEDWARRANTY_DATA_ERR,
            err,
        };
    },

    extendedwarrantyHistoryDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_HISTORY_DATA_BEGIN,
        };
    },

    extendedwarrantyHistoryDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_HISTORY_DATA_SUCCESS,
            data,
        };
    },

    extendedwarrantyHistoryDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_HISTORY_DATA_ERR,
            err,
        };
    },
    
    extendedwarrantyReportDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_REPORT_DATE_BEGIN,
        };
    },

    extendedwarrantyReportDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_REPORT_DATE_SUCCESS,
            data,
        };
    },

    extendedwarrantyReportDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_REPORT_DATE_ERR,
            err,
        };
    },

    extendedwarrantyReportSaveDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_REPORT_SAVE_DATA_BEGIN,
        };
    },

    extendedwarrantyReportSaveDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    extendedwarrantyReportSaveDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    extendedwarrantyReportDownloadDataBegin: () => {
        return {
            type: actions.EXTENDEDWARRANTY_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    extendedwarrantyReportDownloadDataSuccess: (data) => {
        return {
            type: actions.EXTENDEDWARRANTY_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    extendedwarrantyReportDownloadDataErr: (err) => {
        return {
            type: actions.EXTENDEDWARRANTY_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },
    
};

export default actions;