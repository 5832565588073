import React, { lazy } from 'react';
import { Switch, Route,Redirect ,useRouteMatch} from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard         = lazy(() => import('../../container/review/Dashboard'));
const ReviewList        = lazy(() => import('../../container/review/ReviewList'));
const EditReview        = lazy(() => import('../../container/review/EditReview'));
const ViewReview        = lazy(() => import('../../container/review/ViewReview'));
const FollowList        = lazy(() => import('../../container/review/FollowList'));
const Report            = lazy(() => import('../../container/review/Report'));
const DownloadReport    = lazy(() => import('../../container/review/DownloadReport'));
const SummaryReport     = lazy(() => import('../../container/review/SummaryReport'));


function NotFound() {
    return <Redirect to="/admin" />;
}

function ReviewRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            
            <Route exact path={`${path}/medium/:id`} component={ReviewList} />
            <Route exact path={`${path}/medium/:id/edit`} component={EditReview} />
            <Route exact path={`${path}/medium/:id/view`} component={ViewReview} />

            <Route exact path={`${path}/follow/:id`} component={FollowList} />
            <Route exact path={`${path}/follow/:id/edit`} component={EditReview} />
            <Route exact path={`${path}/follow/:id/view`} component={ViewReview} />

            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route exact path={`${path}/summary_report`} component={SummaryReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(ReviewRoutes);