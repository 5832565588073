import React, { lazy } from 'react';
import { Switch, Route,Redirect ,useRouteMatch} from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard                     = lazy(() => import('../../container/defective_unit_delivered/Dashboard'));

const DefectiveUnitDeliveredHappy   = lazy(() => import('../../container/defective_unit_delivered/DefectiveUnitDeliveredHappy'));
const ViewHappyDetails              = lazy(() => import('../../container/defective_unit_delivered/ViewHappyDetails'));

const RegisteredList            = lazy(() => import('../../container/defective_unit_delivered/RegisteredList'));
const ComplaintRegisteredList   = lazy(() => import('../../container/defective_unit_delivered/ComplaintRegisteredList'));

const ViewDetails               = lazy(() => import('../../container/defective_unit_delivered/ViewDetails'));
const EditDetails               = lazy(() => import('../../container/defective_unit_delivered/EditComplaintDetails'))

const FollowSchedule            = lazy(() => import('../../container/defective_unit_delivered/FollowSchedule'));
const FollowPriority            = lazy(() => import('../../container/defective_unit_delivered/FollowPriority'));

const ViewFollowDetails         = lazy(() => import('../../container/defective_unit_delivered/ViewFollowDetails'));

const Report                    = lazy(() => import('../../container/defective_unit_delivered/NewReport'));
const DownloadReport            = lazy(() => import('../../container/defective_unit_delivered/DownloadReport'));

const SummaryReport             = lazy(() => import('../../container/defective_unit_delivered/SummaryReport'));


function NotFound() {
    return <Redirect to="/admin" />;
}

function DefectiveRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />

            <Route exact path={`${path}/follow/:id`} component={RegisteredList} />
            <Route exact path={`${path}/follow/:id/view`} component={ViewDetails} />
            <Route exact path={`${path}/follow/:id/edit`} component={EditDetails} />

            <Route exact path={`${path}/complaint_register/:id`} component={ComplaintRegisteredList} />
            <Route exact path={`${path}/complaint_register/:id/view`} component={ViewDetails} />
            <Route exact path={`${path}/complaint_register/:id/edit`} component={EditDetails} />

            <Route exact path={`${path}/defective_unit_delivered_happy`} component={DefectiveUnitDeliveredHappy} />
            <Route exact path={`${path}/defective_unit_delivered_happy/view`} component={ViewHappyDetails} />

            <Route exact path={`${path}/priority_log/:id`} component={FollowPriority} />
            <Route exact path={`${path}/priority_log/:id/view`} component={ViewFollowDetails} />

            <Route exact path={`${path}/schedule_log/:id`} component={FollowSchedule} />
            <Route exact path={`${path}/schedule_log/:id/view`} component={ViewFollowDetails} />

            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route exact path={`${path}/summary_report`} component={SummaryReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(DefectiveRoutes);