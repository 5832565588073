import React, { lazy } from 'react';
import { Switch, Route,useRouteMatch, Redirect } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard             = lazy(() => import('../../container/maintenance/Dashboard'));
const MaintenanceList       = lazy(() => import('../../container/maintenance/MaintenanceList'));
const MaintenanceFollowList = lazy(() => import('../../container/maintenance/MaintenanceFollowList'));
const MaintenanceView       = lazy(() => import('../../container/maintenance/ViewMaintenance')); 
const EmployeeList          = lazy(() => import('../../container/maintenance/EmployeeList'));
const Report                = lazy(() => import('../../container/maintenance/Report'));
const DownloadReport        = lazy(() => import('../../container/maintenance/DownloadReport'));
const SummaryReport         = lazy(() => import('../../container/maintenance/SummaryReport'));

function NotFound() {
    return <Redirect to="/admin" />;
}

function MaintenanceRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/list`} component={MaintenanceList} />
            <Route exact path={`${path}/list/view`} component={MaintenanceView} />
            <Route exact path={`${path}/follow`} component={MaintenanceFollowList} />
            <Route exact path={`${path}/follow/view`} component={MaintenanceView} />
            <Route exact path={`${path}/employee`} component={EmployeeList} />
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route exact path={`${path}/summary_report`} component={SummaryReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(MaintenanceRoutes);
