import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch,  } from 'react-router-dom'; //Redirect

import { useSelector } from 'react-redux';

import Dashboard from './dashboard';
import Sales from './sales';
import Customer from './customer';
import Store from './store';
import Employee from './employee';
import Inventory from './inventory';
import Feedback from './feedback';
import Walkout from './walkout';
import DataManagement from './datamanagement';
import CustomerQueries from './purchaseQueries';
import Customercare from './customercare';
import Agent from './agent';
import Profile from './profile';
import Maintenance from './maintenance';
import MT_EMP from './maintenance_employee';
import Quality from './quality';
import Warranty from './warranty';
import StockDefective from './stock_defective';
import OutofWarranty from './out_of_warranty';
import Escalation from './escalation';
import Review from './review';
import Defective from './defective';
import Legal from './legal';
import BrandEscalation from './brand_escalation';

import VendorExtended from './vendor/extended_warranty';
import VendorOutofWarranty from './vendor/out_of_warranty';
import VendorInWarranty from './vendor/in_warranty';
import VendorDemoInstall from './vendor/demoinstall';

function Admin() {
    const { path } = useRouteMatch();

    const loginDetails = useSelector(state => state.auth.loginData);
    
    
    return (
        <Switch>
            <Suspense
                fallback={
                    <div className="spin">
                        <Spin />
                    </div>
                }
            >
                <Route exact path={path} component={Dashboard} />
                
                {(loginDetails.loginType === 'admin' || loginDetails.loginType === 'superadmin') && (
                    <>
                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'sales')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/sales`} component={Sales} /> )}
                        
                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'customer')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/customer`} component={Customer} /> )}
                        
                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'employee')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/employee`} component={Employee} /> )}
                        
                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'store')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/store`} component={Store} /> )}
                        
                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'inventory')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/inventory`} component={Inventory} /> )}
                        
                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'feedback')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/feedback`} component={Feedback} /> )}

                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'walkout')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/walkout`} component={Walkout} /> )}

                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'data')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/datamanagement`} component={DataManagement} />  )}

                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'purchase')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/queries`} component={CustomerQueries} /> )}

                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'customercare')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/customercare`} component={Customercare} /> )}

                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'maintenance')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/maintenance`} component={Maintenance} /> )}

                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'maintenance')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/maintenance_employee`} component={MT_EMP} /> )}

                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'quality')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/quality`} component={Quality} /> )}

                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'warranty')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/warranty`} component={Warranty} /> 
                        )}

                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'out_of_warranty')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/out_of_warranty`} component={OutofWarranty} /> 
                        )}

                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'escalation')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/escalation`} component={Escalation} /> 
                        )}

                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'review')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/review`} component={Review} /> 
                        )}

                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'stock-defective')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/stock_defective`} component={StockDefective} /> 
                        )}

                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'defective-unit-delivered')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/defective`} component={Defective} /> 
                        )}

                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'legal-case')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/legal`} component={Legal} /> 
                        )}

                        {((loginDetails.loginType === 'admin' &&  loginDetails.permission.find(item => item === 'brand-escalation')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/brand_escalation`} component={BrandEscalation} /> 
                        )}

                    </>
                )}

                {(loginDetails.loginType === 'agent' || loginDetails.loginType === 'superadmin') && (
                    <>
                        {((loginDetails.loginType === 'agent' &&  loginDetails.permission.find(item => item === 'agent')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/agent`} component={Agent} /> 
                        )}
                    </>
                )}

                {(loginDetails.loginType === 'maintenance' || loginDetails.loginType === 'superadmin') && (
                    <>
                        {((loginDetails.loginType === 'maintenance' &&  loginDetails.permission.find(item => item === 'maintenance')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/maintenance_employee`} component={MT_EMP} /> 
                        )}
                    </>
                )}

                {(loginDetails.loginType === 'stock' || loginDetails.loginType === 'superadmin') && (
                    <>
                        {((loginDetails.loginType === 'stock' &&  loginDetails.permission.find(item => item === 'stock-defective')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/stock`} component={StockDefective} /> 
                        )}
                    </>
                )}

                {(loginDetails.loginType === 'vendor' || loginDetails.loginType === 'superadmin') && (
                    <>
                        {((loginDetails.loginType === 'vendor' &&  loginDetails.permission.find(item => item === 'extended-warranty')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/vendor/extended`} component={VendorExtended} /> 
                        )}

                        {((loginDetails.loginType === 'vendor' &&  loginDetails.permission.find(item => item === 'out-of-warranty')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/vendor/outofwarranty`} component={VendorOutofWarranty} /> 
                        )}

                        {((loginDetails.loginType === 'vendor' &&  loginDetails.permission.find(item => item === 'in-warranty')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/vendor/inwarranty`} component={VendorInWarranty} /> 
                        )}

                        {((loginDetails.loginType === 'vendor' &&  loginDetails.permission.find(item => item === 'demo-installation-warranty')) || loginDetails.loginType === 'superadmin') && (
                            <Route path={`${path}/vendor/demoinstall`} component={VendorDemoInstall} /> 
                        )}

                    </>
                )}

                <Route path={`${path}/profile`} component={Profile} />
                {/* <Route path="*" component={NotFound} /> */}
                {/* <Route path={`${path}/profile/myProfile`} component={Myprofile} /> */}
            </Suspense>
        </Switch>
    );
}

export default Admin;
