import actions from './actions';
import { DataService } from '../../config/data/dataService';
const { 
    maintenanceDashboardLeadSourceDataBegin, maintenanceDashboardLeadSourceDataSuccess, maintenanceDashboardLeadSourceDataErr,
    maintenanceDashboardCategoryDataBegin, maintenanceDashboardCategoryDataSuccess, maintenanceDashboardCategoryDataErr,
    maintenanceDashboardTicketDataBegin, maintenanceDashboardTicketDataSuccess, maintenanceDashboardTicketDataErr,
    maintenanceDashboardSummaryDataBegin, maintenanceDashboardSummaryDataSuccess, maintenanceDashboardSummaryDataErr,
    maintenanceDashboardRegionDataBegin, maintenanceDashboardRegionDataSuccess, maintenanceDashboardRegionDataErr,
    maintenanceDepartmentDataBegin,maintenanceDepartmentDataSuccess,maintenanceDepartmentDataErr,
    maintenanceListDataBegin, maintenanceListDataSuccess, maintenanceListDataErr, 
    maintenanceLeadAssignDataBegin, maintenanceLeadAssignDataSuccess, maintenanceLeadAssignDataErr, maintenanceLeadAssignDataReset,
    
    getAssignedMaintenanceListDataBegin, getAssignedMaintenanceListDataSuccess, getAssignedMaintenanceListDataErr,
    updateMaintenanceStatusDataBegin,updateMaintenanceStatusDataSuccess,updateMaintenanceStatusDataErr,updateMaintenanceStatusDataReset,
    getMaintenanceStatusHistoryDataBegin,getMaintenanceStatusHistoryDataSuccess,getMaintenanceStatusHistoryDataErr,
    
    maintenanceReportDataBegin, maintenanceReportDataSuccess, maintenanceReportDataErr, 
    maintenanceReportSaveDataBegin, maintenanceReportSaveDataSuccess, maintenanceReportSaveDataErr, 
    maintenanceReportDownloadDataBegin, maintenanceReportDownloadDataSuccess, maintenanceReportDownloadDataErr, 

    maintenanceEmployeeReportDataBegin, maintenanceEmployeeReportDataSuccess, maintenanceEmployeeReportDataErr, 
    maintenanceEmployeeReportSaveDataBegin, maintenanceEmployeeReportSaveDataSuccess, maintenanceEmployeeReportSaveDataErr, 
    maintenanceEmployeeReportDownloadDataBegin, maintenanceEmployeeReportDownloadDataSuccess, maintenanceEmployeeReportDownloadDataErr, 
} = actions;

const getMaintenanceDashboardReason = (data) => {
    return async (dispatch) => {
        try {
            dispatch(maintenanceDashboardLeadSourceDataBegin());
            const response = await DataService.post('/dashboardGetMaintenanceReason',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceDashboardLeadSourceDataSuccess(response.data.data,response.data.start,response.data.end));
            } else {
                dispatch(maintenanceDashboardLeadSourceDataErr());
            }
        } catch (err) {
            dispatch(maintenanceDashboardLeadSourceDataErr(err));
        }
    };
};

const getMaintenanceCategoryCount = (data) => {
    return async (dispatch) => {
        try {
            dispatch(maintenanceDashboardCategoryDataBegin());
            const response = await DataService.post('/dashboardGetMaintenanceCategoryCount',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceDashboardCategoryDataSuccess(response.data.data,response.data.start,response.data.end));
            } else {
                dispatch(maintenanceDashboardCategoryDataErr());
            }
        } catch (err) {
            dispatch(maintenanceDashboardCategoryDataErr(err));
        }
    };
};

const getMaintenanceTicketStatus = (data) => {
    return async (dispatch) => {
        try {
            dispatch(maintenanceDashboardTicketDataBegin());
            const response = await DataService.post('/dashboardGetMaintenanceTicketStatusCount',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceDashboardTicketDataSuccess(response.data.data,response.data.start,response.data.end));
            } else {
                dispatch(maintenanceDashboardTicketDataErr());
            }
        } catch (err) {
            dispatch(maintenanceDashboardTicketDataErr(err));
        }
    };
};

const getMaintenanceSummary = () => {
    return async (dispatch) => {
        try {
            dispatch(maintenanceDashboardSummaryDataBegin());
            const response = await DataService.get('/dashboardMaintenanceEnquiryCount');
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceDashboardSummaryDataSuccess(response.data.data));
            } else {
                dispatch(maintenanceDashboardSummaryDataErr());
            }
        } catch (err) {
            dispatch(maintenanceDashboardSummaryDataErr(err));
        }
    };
};

const getMaintenanceDashboardRegion = (data) => {
    return async (dispatch) => {
        try {
            dispatch(maintenanceDashboardRegionDataBegin());
            const response = await DataService.post('/dashboardGetMaintenanceRegion',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceDashboardRegionDataSuccess(response.data.data,response.data.start,response.data.end));
            } else {
                dispatch(maintenanceDashboardRegionDataErr());
            }
        } catch (err) {
            dispatch(maintenanceDashboardRegionDataErr(err));
        }
    };
};

const getMaintenanceDepartment = (data) => {
    return async (dispatch) => {
        try {
            dispatch(maintenanceDepartmentDataBegin());
            const response = await DataService.get('/getMaintenanceDepartment');
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceDepartmentDataSuccess(response.data.data.object_list));
            } else {
                dispatch(maintenanceDepartmentDataErr());
            }
        } catch (err) {
            dispatch(maintenanceDepartmentDataErr(err));
        }
    };
}

const getAllMaintenanceList = (data) => {
    return async (dispatch) => {
        try {
            dispatch(maintenanceListDataBegin());
            const response = await DataService.post('/getAllMaintenanceList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceListDataSuccess(response.data.data));
            } else {
                dispatch(maintenanceListDataErr());
            }
        } catch (err) {
            dispatch(maintenanceListDataErr(err));
        }
    };
};


const maintenanceLeadAssign = (data) => {
    return async (dispatch) => {
        try {
            dispatch(maintenanceLeadAssignDataBegin());
            const response = await DataService.post('/assignMaintenanceEmployee',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceLeadAssignDataSuccess(response.data.data));
            } else {
                dispatch(maintenanceLeadAssignDataErr());
            }
        } catch (err) {
            dispatch(maintenanceLeadAssignDataErr(err));
        }
    };
};

const resetMaintenanceLeadAssign = () => {
    return async (dispatch) => {
        dispatch(maintenanceLeadAssignDataReset());
    };
}

const getAssignedMaintenanceList = (data) => {
    return async (dispatch) => {
        try {
            dispatch(getAssignedMaintenanceListDataBegin());
            const response = await DataService.post('/maintenanceEmployeeBasedList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(getAssignedMaintenanceListDataSuccess(response.data.data));
            } else {
                dispatch(getAssignedMaintenanceListDataErr());
            }
        } catch (err) {
            dispatch(getAssignedMaintenanceListDataErr(err));
        }
    };
}

const updateMaintenanceStatus = (data) => {
    return async (dispatch) => {
        try {
            dispatch(updateMaintenanceStatusDataBegin());
            const response = await DataService.post('/updateMaintenanceStatus',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(updateMaintenanceStatusDataSuccess(response.data.data));
            } else {
                dispatch(updateMaintenanceStatusDataErr());
            }
        } catch (err) {
            dispatch(updateMaintenanceStatusDataErr(err));
        }
    };
};

const resetUpdateMaintenanceStatus = () => {
    return async (dispatch) => {
        dispatch(updateMaintenanceStatusDataReset());
    };
}

const getMaintenanceStatusHistory = (data) => {
    return async (dispatch) => {
        try {
            dispatch(getMaintenanceStatusHistoryDataBegin());
            const response = await DataService.post('/maintenanceStatusHistory',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(getMaintenanceStatusHistoryDataSuccess(response.data.data));
            } else {
                dispatch(getMaintenanceStatusHistoryDataErr());
            }
        } catch (err) {
            dispatch(getMaintenanceStatusHistoryDataErr(err));
        }
    };
};

const getMaintenanceReport = (data) => {
    return async (dispatch) => {
        try {
            dispatch(maintenanceReportDataBegin());
            const response = await DataService.post('/getMaintenanceReport',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceReportDataSuccess(response.data.data));
            } else {
                dispatch(maintenanceReportDataErr());
            }
        } catch (err) {
            dispatch(maintenanceReportDataErr(err));
        }
    };
};

const getMaintenanceReportSummary = (data) => {
    return async (dispatch) => {
        try {
            dispatch(maintenanceReportDataBegin());
            const response = await DataService.post('/getMaintenanceReportSummary',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceReportDataSuccess(response.data.data));
            } else {
                dispatch(maintenanceReportDataErr());
            }
        } catch (err) {
            dispatch(maintenanceReportDataErr(err));
        }
    };
};


const getMaintenanceReportSave = (data) => {
    return async (dispatch) => {
        try {
            dispatch(maintenanceReportSaveDataBegin());
            const response = await DataService.post('/getMaintenanceReportSave',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceReportSaveDataSuccess(response.data.data));
            } else {
                dispatch(maintenanceReportSaveDataErr());
            }
        } catch (err) {
            dispatch(maintenanceReportSaveDataErr(err));
        }
    };
};

const getMaintenanceReportDownload = (data) => {
    return async (dispatch) => {
        try {
            dispatch(maintenanceReportDownloadDataBegin());
            const response = await DataService.post('/getMaintenanceReportDownload',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceReportDownloadDataSuccess(response.data.data));
            } else {
                dispatch(maintenanceReportDownloadDataErr());
            }
        } catch (err) {
            dispatch(maintenanceReportDownloadDataErr(err));
        }
    };
};

const getMaintenanceEmployeeReport = (data) => {
    return async (dispatch) => {
        try {
            dispatch(maintenanceEmployeeReportDataBegin());
            const response = await DataService.post('/getMaintenanceEmployeeReport',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceEmployeeReportDataSuccess(response.data.data));
            } else {
                dispatch(maintenanceEmployeeReportDataErr());
            }
        } catch (err) {
            dispatch(maintenanceEmployeeReportDataErr(err));
        }
    };
};



const getMaintenanceEmployeeReportSave = (data) => {
    return async (dispatch) => {
        try {
            dispatch(maintenanceEmployeeReportSaveDataBegin());
            const response = await DataService.post('/getMaintenanceEmployeeReportSave',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceEmployeeReportSaveDataSuccess(response.data.data));
            } else {
                dispatch(maintenanceEmployeeReportSaveDataErr());
            }
        } catch (err) {
            dispatch(maintenanceEmployeeReportSaveDataErr(err));
        }
    };
};

const getMaintenanceEmployeeReportDownload = (data) => {
    return async (dispatch) => {
        try {
            dispatch(maintenanceEmployeeReportDownloadDataBegin());
            const response = await DataService.post('/getMaintenanceEmployeeReportDownload',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceEmployeeReportDownloadDataSuccess(response.data.data));
            } else {
                dispatch(maintenanceEmployeeReportDownloadDataErr());
            }
        } catch (err) {
            dispatch(maintenanceEmployeeReportDownloadDataErr(err));
        }
    };
};


export { 
    getMaintenanceDashboardReason, getMaintenanceCategoryCount, getMaintenanceTicketStatus, getMaintenanceSummary, getMaintenanceDashboardRegion, 
    getMaintenanceDepartment, 
    getAllMaintenanceList, 
    maintenanceLeadAssign, resetMaintenanceLeadAssign,
    getAssignedMaintenanceList,updateMaintenanceStatus,resetUpdateMaintenanceStatus,getMaintenanceStatusHistory,
    getMaintenanceReport, getMaintenanceReportSummary, getMaintenanceReportSave,  getMaintenanceReportDownload, 
    getMaintenanceEmployeeReport,   getMaintenanceEmployeeReportSave, getMaintenanceEmployeeReportDownload
}; 
