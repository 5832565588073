import salesImage from '../static/img/login-icon/admin/sales.svg';
import customerImage from '../static/img/login-icon/admin/customer.svg';
import employeeImage from '../static/img/login-icon/admin/employee.svg';
import storeImage from '../static/img/login-icon/admin/store.svg';
import inventoryImage from '../static/img/login-icon/admin/inventory.svg';
import feedbackImage from '../static/img/login-icon/admin/feedback.svg';
import missingImage from '../static/img/login-icon/admin/missed-order.svg';
import warrantyImage from '../static/img/login-icon/admin/warranty.svg';
import outofwarrantyImage from '../static/img/login-icon/admin/outofwarranty.svg';
import escalationImage from '../static/img/login-icon/admin/escalation.svg';
import customerQueriesImage from '../static/img/login-icon/admin/customer-queries.svg';
import stockImage from '../static/img/login-icon/admin/stock-defective.svg';
import customerCareImage from '../static/img/login-icon/admin/customercare.svg';
import maintenanceImage from '../static/img/login-icon/admin/maintenance.svg';
import dataImage from '../static/img/login-icon/admin/data.svg';
import qualityImage from '../static/img/login-icon/admin/quality.svg';
import reviewImage from '../static/img/login-icon/admin/review.svg';
import defectiveUnitDeliveredImage from '../static/img/login-icon/admin/defective-unit-delivered.svg';
import legalCaseImage from '../static/img/login-icon/admin/legal-case.svg';
import demoInstallation from '../static/img/login-icon/admin/demo-installation.svg';
import inWarranty from '../static/img/login-icon/admin/in-warranty.svg';
import brandEscalation from '../static/img/login-icon/admin/brand-escalation.svg';

const adminPortalIcon = () => {
    const portalIcon = [
        {title:'Sales',value:'sales', image_url:salesImage},
        {title:'Customer',value:'customer', image_url:customerImage}, 
        {title:'Employee',value:'employee', image_url:employeeImage},
        {title:'Store',value:'store', image_url:storeImage},
        {title:'Inventory',value:'inventory', image_url:inventoryImage},
        {title:'Walkout Data',value:'walkout', image_url:missingImage},
        {title:'Data Management',value:'data', image_url:dataImage},
        {title:'Purchase Queries',value:'purchase', image_url:customerQueriesImage},
        {title:'Call Monitoring',value:'customercare', image_url:customerCareImage},
        {title:'Maintenance',value:'maintenance', image_url:maintenanceImage},
        {title:'Quality & Training',value:'quality', image_url:qualityImage},
        {title:'Escalation',value:'escalation', image_url:escalationImage},
        {title:'Review',value:'review', image_url:reviewImage},
        {title:'Stock Defective',value:'stock-defective', image_url:stockImage},
        {title:'Defective Unit Delivered',value:'defective-unit-delivered', image_url:defectiveUnitDeliveredImage},
        {title:'Legal Case',value:'legal-case', image_url:legalCaseImage},
        {title:'Extended Warranty',value:'warranty', image_url:warrantyImage},
        {title:'Out of Warranty',value:'out-of-warranty', image_url:outofwarrantyImage},
        {title:'IW Repair & Service',value:'in-warranty', image_url:inWarranty},
        {title:'Demo & Installation',value:'demo-installation-warranty', image_url:demoInstallation},
        {title:'Brand Escalation',value:'brand-escalation', image_url:brandEscalation},
    ];

    return portalIcon;
}

const agentPortalIcon = () => {
    const portalIcon = [{title:'Customer Care',value:'agent', image_url:customerCareImage}];
    return portalIcon;
}

const employeePortalIcon = () => {
    const portalIcon = [
        {title:'Inventory',value:'inventory', image_url:inventoryImage},
        {title:'Walkout Data',value:'walkout', image_url:missingImage},
        {title:'Walkin Complaints',value:'walkin', image_url:warrantyImage},
        {title:'Purchase Enquiry',value:'purchase', image_url:customerQueriesImage},
        {title:'Customer FeedBack',value:'feedback', image_url:feedbackImage},
        {title:'Maintenance',value:'maintenance', image_url:maintenanceImage},
        {title:'Stock Defective',value:'stock-defective', image_url:stockImage},
        {title:'Review',value:'review', image_url:reviewImage},
    ];
    return portalIcon;
}

const maintenancePortalIcon = () => {
    const portalIcon = [
        {title:'Maintenance',value:'maintenance', image_url:maintenanceImage},
    ];
    return portalIcon;
}

const stockPortalIcon = () => {
    const portalIcon = [
        {title:'Stock Defective',value:'stock-defective', image_url:stockImage},
    ];
    return portalIcon;
}

const vendorPortalIcon = () => {
    const portalIcon = [
        {title:'OW Repair & Service',value:'out-of-warranty', image_url:outofwarrantyImage},
        {title:'EW Repair & Service',value:'extended-warranty', image_url:warrantyImage},
        {title:'IW Repair & Service',value:'in-warranty', image_url:inWarranty},
        {title:'Demo & Installation',value:'demo-installation-warranty', image_url:demoInstallation},
    ];
    return portalIcon;
}

export { adminPortalIcon, agentPortalIcon, employeePortalIcon, maintenancePortalIcon, stockPortalIcon, vendorPortalIcon };